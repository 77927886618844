<template>
  <div>
    <div class="level-left search-bar">
      <div class="level-item" style="margin-left: 1rem">
        <h4 class="is-bold is-uppercase">Tìm kiếm khách hàng</h4>
      </div>
      <div class="level-item">
        <b-input v-model="customerPhone" class="customer-phone-input" placeholder="Số điện thoại"></b-input>
      </div>
      <b-button type="is-dark" @click="search">Tìm kiếm</b-button>
    </div>
    <div class="customer-value">
      <div class="chart-container">
        <h3>Dịch vụ</h3>
        <apexchart
          type="pie"
          width="600"
          :options="serviceValueChart.chartOptions"
          :series="serviceValueChart.series"
        />
      </div>
      <div class="chart-container">
        <h3>Sản phẩm</h3>
        <apexchart
          type="pie"
          width="600"
          :options="productValueChart.chartOptions"
          :series="productValueChart.series"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerPhone: "",
      serviceValueChart: {
        series: [2300000, 1134000, 360000, 150000, 150000, 20000],
        chartOptions: {
          labels: [
            "Shine Combo 100k (28)",
            "Combo Uốn 260k (5)",
            "Color Combo 180k (2)",
            "Đắp mặt nạ 20k (8)",
            "Tẩy da chết 20k (8)",
            "Lấy mụn mũi chuyên sâu 20k (1)"
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      },
      productValueChart: {
        series: [500000, 224000, 224000, 289000, 130000, 200000],
        chartOptions: {
          labels: [
            "Sáp By Vilain Gold Digger 65g (1)",
            "DCR Gillette Fushion (1)",
            "LCR Gillette Fushion (1)",
            "Máy sấy tóc (1)",
            "Gôm Lady Killer (1)",
            "Khác"
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      }
    };
  },
  methods: {
    search() {}
  },
  created() {}
};
</script>

<style>
.customer-phone-input {
  width: 200px;
}

.search-bar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.customer-value {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.chart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
</style>
